import Vue from "vue";
import store from "./store";
import router from "./router";
const App = () => import("./App.vue")
//import App from "./App.vue";
import TableService from "./services/table.service";
import AdminService from "./services/admin.service";
import GoogleService from "./services/google.service";
import AlertService from "./services/alert.service";

const smartCenterEnabled = process.env.VUE_APP_SMART_CENTER_ACTIVE;

var dt = require("datatables.net");
require("datatables.net-dt");
require("datatables.net-colreorder");
require("datatables.net-bs4");
require("datatables.net-buttons");
require("jszip");
require("datatables.net-buttons/js/buttons.html5.js");
import "datatables.net-responsive/js/dataTables.responsive.min.js";
import "bootstrap";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import { isMobile } from "mobile-device-detect";
import jQuery, { get } from "jquery";
import "datatables.net-fixedcolumns-bs4/js/fixedColumns.bootstrap4.min.js";
import "datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css";

import "./rum/rum-admin.js";

  jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "non-empty-string-asc": function(a, b) {
      return TableService.nonEmptyAsc(a,b);
    },
    "non-empty-string-desc": function(a, b) {
      return TableService.nonEmptyDesc(a, b);
    }
  });
  let admin = {
  data() {
    return {
      clientObject: {},
      surveyCode: "",
      loading: false,
      disableSurveyDropdown: false,
      showMenuIcon: false,
      showMainMenu: !isMobile,
      showHeaderMenu: false,
      showWizardContent: false,
      smartCenterEnabled: smartCenterEnabled == "true" || smartCenterEnabled == true
    };
  },
  methods: {
    getClientObject() {
      return this.clientObject;
    },
    setClientObject(clientObject) {
      this.clientObject = clientObject;
    },
    getSurveyCode() {
      return this.surveyCode;
    },
    setSurveyCode(surveyCode) {
      this.surveyCode = surveyCode;
    },
    getDisableSurveyDropdown() {
      return this.disableSurveyDropdown;
    },
    setDisableSurveyDropdown(disableSurveyDropdown) {
      this.disableSurveyDropdown = disableSurveyDropdown;
    },
    setShowWizardContent(showWizardContent){
      this.showWizardContent = showWizardContent;
    },
    getShowWizardContent(){
      return this.showWizardContent;
    },
    setShowMainMenu(showMainMenu){
      this.showMainMenu = showMainMenu;
    },
    getShowMainMenu(){
      return this.showMainMenu;
    },
    getShowMenuIcon() {
      return this.showMenuIcon;
    },
    setShowMenuIcon(showMenuIcon) {
      this.showMenuIcon =   showMenuIcon;
    },
    setShowHeaderMenu(showHeaderMenu) {
      this.showHeaderMenu = showHeaderMenu;
    },
    getShowHeaderMenu() {
      return this.showHeaderMenu;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    getLoading() {
      return this.loading;
    },
    isSmartCenterEnabled() {
      return this.smartCenterEnabled;
    },
    toggleHeaderMenu: function() {
      this.showHeaderMenu = !this.showHeaderMenu;
    },
    toggleLeftNav: function() {
      this.showMainMenu = !this.showMainMenu;
    },
    toggleWizardContent: function(){
      let label = this.$root.getShowWizardContent() ? "close" : "open";
      GoogleService.logClickEvent("Wizard Toggle", label, 0, this);         

      this.showWizardContent = !this.showWizardContent;
    },
    closeLeftNav: function() {
      this.showMainMenu = false;
    },
    showModal: function(name) {
      this.$modal.show(name);
    },
    processServerRequest(message = ""){
      let timeOut = 1000; // 1000
      this.setLoading(true);
      if (message != ""){
        message += " please stand by......";
        let vm = this;
        setTimeout(function() {
          if (vm.loading){
            AdminService.displayInfoMessage({ text: message, duration: -1 });
          }
        }, timeOut);        
      }
    },
    processServerResponse(closeAlert = false){
      this.setLoading(false);
      if (closeAlert){
        AlertService.closeAlert();
      }
    }
  },
  render: h => h(App)
};

export default admin;
