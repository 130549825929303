<template>
  <div class="container">
    <input
      type="checkbox"
      class="form-check-input"
      :id="clientChartToReportPageTypeObject.clientChartId + '_' + clientChartToReportPageTypeObject.name"
      :name="clientChartToReportPageTypeObject.clientChartId + '_' + clientChartToReportPageTypeObject.name"
      v-model="include"
    />
    <label class="custom-checkout" :for="clientChartToReportPageTypeObject.clientChartId + '_' + clientChartToReportPageTypeObject.name"
      >{{ clientChartToReportPageTypeObject.name }}
    </label>
    <div class="d-inline float-right">
      <a href="#!" @click="displayInsights(clientChartToReportPageTypeObject, clientChartToReportPageTypeObject.name)">
        <span class="mr-1">Analysis</span>
        <font-awesome-icon :icon="['fa', 'pen-square']" size="lg" />
      </a>
    </div>
  </div>
</template>
<script>
import AdminService from "../../services/admin.service";
import ReportService from "../../services/report.service";
import AlertService from "../../services/alert.service";

export default {
  name: "ClientChartToReportPageType",
  mixins: [],
  components: {},
  props: {
    clientChartToReportPageTypeObject: {
      type: Object,
      default: function() {
        return {
          clienChartId: 0,
          name: "",
          include: true
        };
      }
    },
    clientChartObject: {
      type: Object,
      default: function() {
        return {
          clienChartId: 0
        };
      }
    }
  },
  data() {
    return {
      clientChart: {
        clientChartId: 0
      },
      include: false,
      reportPagesList: []
    };
  },
  methods: {
    getReportPagesCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.reportPagesList = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      AlertService.closeAlert();
    },
    getReportPages(clientChart) {
      ReportService.getReportPages(clientChart.clientChartId, this.getReportPagesCallback, this);
    },
    displayInsights(clientChartToReportPageTypeObject, reportPageTypeName) {
      let reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
      this.$parent.$parent.displayInsights(reportPageInput, this.clientChart, clientChartToReportPageTypeObject.name);
    }
  },
  created() {},
  mounted() {
    this.clientChart = JSON.parse(JSON.stringify(this.clientChartObject));
    this.include = this.clientChartToReportPageTypeObject.include;
  },
  watch: {
    include: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          let returnObject = {
            clientChartId: this.clientChartToReportPageTypeObject.clientChartId,
            name: this.clientChartToReportPageTypeObject.name,
            sequenceNumber: this.clientChartToReportPageTypeObject.sequenceNumber,
            include: newVal
          };
          this.$emit("update", this.clientChartToReportPageTypeObject.clientChartId, this.clientChartToReportPageTypeObject.name, returnObject);
        }
      }
    },     
    clientChart: function() {
      if (typeof this.clientChart != "undefined" && this.clientChart.clientChartId != 0) {
        ReportService.getReportPages(this.clientChart.clientChartId, this.getReportPagesCallback, this);
      }
    }
  }
};
</script>
