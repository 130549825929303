<template>
  <div class="w-75 border-bottom pt-1 pb-3">
    <div class="row pt-3">
      <h4>Import Type</h4>
    </div>
    <div class="row">
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";

import moment from "moment";
Vue.prototype.moment = moment;

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import AlertService from "../../services/alert.service";

export default {
  name: "ImportType",
  mixins: [AdminMixins, DataTableMixins],
  components: {},
  data() {
    return {
      title: "ImportType",
      table: TableService.getImportTypeAttributes()
    };
  },
  methods: {
    async getTableData() {
      this.$root.processServerRequest("Loading Auto Import Data");
      this.modalReturnMessage = "Loading Auto Import Data please stand by....";
      this.setModalLoading(true);
      AdminService.getImportType(this.getImportTypeCallback, this);
    },
    getImportTypeCallback(response) {
      this.setModalLoading(true);
      let importTypes = response.data.data.importTypes;
      let awsDirListData = response.data.data.awsDirCount;
      importTypes = importTypes.map(item => {
        let awsData = awsDirListData[item.name];
        if (awsData) {
            item.processedCount = awsData.processed || 0;
            item.errorCount = awsData.error || 0;
        } else {
            item.processedCount = 0;
            item.errorCount = 0;
        }
        item.displayName = this.$parent.inputTypeDisplayNameMapping[item.name];
        return item;
      });
      this.table.data = importTypes;
      this.$parent.importTypeList = this.table.data;
      $.extend(this.table.configuration, { columns: this.table.columns });
      $.extend(this.table.configuration, { data: this.table.data });
      this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
      this.buildResponsiveTable();
      AlertService.closeAlert();
      this.setModalLoading(false);
      this.$parent.isImportTypesTableLoaded = true;
    },
    loadData() {
      this.getTableData();
      this.table.configuration.order = [[1, "desc"]];
      this.table.configuration.dom = "frtpB";
      this.table.configuration.responsive = false;
    }
  },
  computed: {},
  created() {
    if (this.isAdmin) {
      this.loadData();
    }
  },
  props: {},
  mounted() {}
};
</script>
