import "./plugins/axios";
import InterviewApp from "./interview-application/InterviewApp.vue";
import "./rum/rum-interview.js";

const smartCenterEnabled = process.env.VUE_APP_SMART_CENTER_ACTIVE;
let interview = {
  data() {
    return {
      loading: false,
      smartCenterEnabled: smartCenterEnabled == "true" || smartCenterEnabled == true
    };
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    getLoading() {
      return this.loading;
    },
    isSmartCenterEnabled() {
      return this.smartCenterEnabled;
    },
    showModal: function(name) {
      this.$modal.show(name);
    }
  },
  render: h => h(InterviewApp)
};

export default interview;