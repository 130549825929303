import $, { type } from "jquery";
import ScatterService from "./scatter.service";
import SymbolService from "../symbol.service";
export default class CommonService {
    /*** xAxis Functions ***/
    getBaseXAxis(){
      return {
          type: 'category',
          gridIndex: 0,
          data: [],              
          axisLabel: {
              fontSize: 10.5,
              fontFamily: "Franklin Gothic Demi Cond",
              color: "#000",
          },
          axisTick:{
              show: false,
          },   
          axisLine: {
            show: false,
          },
          splitLine:{}     
      };
    }    
    getXAxis(gridIndex = 0){
      let xAxis = this.getBaseXAxis()
      let gridIndexForAxis = {
          gridIndex: gridIndex,
      }
      $.extend(true, xAxis, gridIndexForAxis);          
      return xAxis;
    }    
    getEmptyXAxis(){
      return     {
        type: "category",
        gridIndex: 0,
        data: [],
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false,
        }
      };      
    }
    getXAxisForNeeds(displayType = 'need',breakPointList = [],showSplitLine = false){
      let xAxis = this.getBaseXAxis()
      // add one to each entry in the breakPointList
      breakPointList = breakPointList.map(x => x + 1);
      let splitLine = {
          show: showSplitLine,
          lineStyle: {
              color: "#C0C0C0",
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
      };             
      if (displayType == "match-moat") {
        let splitLineForMoat = {        
            color: "#1c408e",
            width: 1,
        }
        splitLine.lineStyle = JSON.parse(JSON.stringify(splitLineForMoat));
      }        
      $.extend(true, xAxis.splitLine, splitLine);          
      $.extend(true, xAxis, this.getXAxis(0));          
      return xAxis;
  }
    /*** yAxis Functions ***/
    getBaseYAxis(){
        let axisLabel = {}   
        $.extend(true, axisLabel, ScatterService.getDefaultAxisTextStyle());          
        let baseYAxis = {
            type: 'value',
            gridIndex: 0,
            axisLabel: axisLabel ,
            splitLine: {
                lineStyle: {
                color: "#fff",
                }
            },       
            min: 1,
            axisTick: {
                show: false
            }, 
            axisLine: {
              show: false,
            },
        };        
        return baseYAxis;
    }
    getYAxisForPricingPower(maxPricePower = 5,showLabel = true){
        let axisLabelForPricingPower = this.getAxisLabelForPercent();
        axisLabelForPricingPower.show = showLabel;
        $.extend(true, axisLabelForPricingPower, ScatterService.getDefaultAxisTextStyle());           
        let yYAxisForPricingPower = this.getBaseYAxis();
        let yYAxisForPricingPowerAdditional = {
            boundaryGap: true,
            splitLine: {
                show: false
            },
            axisLine: {
                show: false,
            },
            axisLabel: axisLabelForPricingPower,
            min: 0,
            max: maxPricePower
        };
        $.extend(true, yYAxisForPricingPower, yYAxisForPricingPowerAdditional);          
        return yYAxisForPricingPower;
    }
    getYAxisForNeed(min = 1, max = 10){
        let yAxisForNeed = this.getBaseYAxis()
        let yAxisForNeedAdditional = {
            min: min,
            max: max,
            splitNumber: 10,
        }
        $.extend(true, yAxisForNeed, yAxisForNeedAdditional);   
        return yAxisForNeed;
    }    
    getDefaultMarkLineForMoat(){
      return {
          symbol: "none",
          label: {
            show: false
          },
          data: [
            {
              yAxis: 10,
              lineStyle: {
                color: "#F4E0E0",
                type: "solid",
                width:2
              },            
            },
            {
              yAxis: 35,
              lineStyle: {
                color: "#FFFF66",
                type: "solid",
                width:2
              },            
            },
            {
              yAxis: 75,
              lineStyle: {
                color: "#EBF1DE",
                type: "solid",
                width:2
              },       
            },
            {
              yAxis: 90,
              lineStyle: {
                color: "#D8E4BC",
                type: "solid",
                width:2
              },            
            }          
          ]
        }
  }    
    getDefaultMarkLineForEmotionalNeeds(lineColor = "#C0C0C0"){
      return {
        name: 'line',
        symbol: 'none',
        label: {
          show: false
        },
        lineStyle: {
          color: lineColor,
          type: 'solid'
        },
        data: [
          {
            name: 'start',
            yAxis: 5.5,
          }          
        ]
      };   
    }
    /***  Series Functions ***/
    getMetricSeries(name, gridIndex,xAxisIndex,yAxisIndex,itemColor = "rgb(215, 228, 189)",useFixedBarWidth = true,useBarMaxWidth = false){
      let metricBarSeries = {
              name: name,
              type: "bar",
             // barWidth: 25,
              gridIndex: gridIndex,
              xAxisIndex: xAxisIndex,
              yAxisIndex: yAxisIndex,
              itemStyle: {
                  color: itemColor
              },
              data: []
      };
      if (useFixedBarWidth == true){
        $.extend(true, metricBarSeries, {barWidth: 25});
      }
      if (useBarMaxWidth == true){
        $.extend(true, metricBarSeries, {barMaxWidth: 100});
      }
      return metricBarSeries;
    }    
    /***  Parse Functions ****/
    parseLegendAndColorData(inputData, outputData, moveFirstColorToEnd = true){
        if (typeof inputData !== 'undefined' && inputData !== null) {
          if (typeof inputData.options !== 'undefined' && inputData.options !== null) {
            if (typeof inputData.options.colors !== 'undefined' && inputData.options.colors !== null) {
              outputData.colors = inputData.options.colors;
              if (moveFirstColorToEnd == true) {
                // move the first element in the array to the end of the array
                let firstColor = outputData.colors.shift();
                outputData.colors.push(firstColor);
              }
            }
          }
          if (typeof inputData.otherData !== 'undefined' && inputData.otherData !== null) {
            let otherData = inputData.otherData;
            let pricingPowerSeriesNumber = otherData["pricingPowerSeriesNumber"];
            if (typeof pricingPowerSeriesNumber != "undefined") {
              outputData.pricingPowerSeriesNumber = pricingPowerSeriesNumber;
            }          
            if (typeof otherData.legend !== 'undefined' && otherData.legend !== null) {
              outputData.legend = {
                normal: [],
                respondents: []
              };
              let inputLegend = otherData["legend"];
              for (let i = 0; i < inputLegend.length; i++) {
                let legend = inputLegend[i];
                if (typeof legend.column !== 'undefined' && legend.column !== null) {
                  let respondentsName = legend.count != 0 ? legend.baseName + " (n=" + legend.count + ")" : legend.baseName;
                  let outputLegend = {
                    elementId: legend.elementId,
                    name: legend.baseName,
                    count: legend.count,
                    symbolClass: legend.symbolClass,
                    symbolColor: legend.symbolColor
                  };
                  outputData.legend.normal.push(outputLegend);
                  let respondentOtputLegend = {
                    elementId: legend.elementId,
                    name: respondentsName,
                    count: legend.count,
                    symbolClass: legend.symbolClass,
                    symbolColor: legend.symbolColor
                  };
                  outputData.legend.respondents.push(respondentOtputLegend);
                }
              }
            }
            outputData.breakPointList = [];
            // get tableStakesCount coreCount divergeCount
            if (typeof otherData.breakPointList !== 'undefined' && otherData.breakPointList !== null){
              outputData.breakPointList = JSON.parse(JSON.stringify(otherData.breakPointList));
            }
            outputData.winnerMapNames = [];
            if (typeof otherData.winnerMapNames !== 'undefined' && otherData.winnerMapNames !== null){
              outputData.winnerMapNames = JSON.parse(JSON.stringify(otherData.winnerMapNames));
            }
            outputData.needsList = [];
            if (typeof otherData.needsList !== 'undefined' && otherData.needsList !== null){
              outputData.needsList = JSON.parse(JSON.stringify(otherData.needsList));
              let seenNeedNames = new Set();
              outputData.needsList = outputData.needsList.filter(item => {
                if (seenNeedNames.has(item.needName)) {
                  return false;
                } else {
                  seenNeedNames.add(item.needName);
                  return true;
                }
              });            
            }
            outputData.driverList = [];
            if (typeof otherData.driverList !== 'undefined' && otherData.driverList !== null){
              outputData.driverList = JSON.parse(JSON.stringify(otherData.driverList));
            }
          }
        }
        return outputData;
    }
    parseData(chartNumber, inputData, outputData, itemsToRemove, includePricingPower = true){
        if (chartNumber == 1) {
          outputData.chart1 = {
            data: [],
            needsList: [],
            containerId: ""
          };
        } else if (chartNumber == 2) {
          outputData.chart2 = {
            data: [],
            needsList: [],
            containerId: ""
          };
        }      
        if (includePricingPower == true) {
          itemsToRemove.lastColumns = 1;
        }
        if (typeof inputData !== 'undefined' && inputData !== null) {      
          if (typeof inputData.rows !== 'undefined' && inputData.rows !== null) {      
            let rows = inputData.rows;
            let removeFirstRowCount = 0;
            while (itemsToRemove.firstRows > removeFirstRowCount) {
              let indexToRemove = 0;
              rows.splice(indexToRemove, 1);
              removeFirstRowCount++;
            }

            let removeRowCount = 0;
            while (itemsToRemove.lastRows > removeRowCount) {
              let indexToRemove = rows.length - 1;
              rows.splice(indexToRemove, 1);
              removeRowCount++;            
            }
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              let rowData = [];
              if (typeof row.c !== 'undefined' && row.c !== null) {
                let columns = row.c;
                for (let j = 0; j < columns.length; j++) {
                  let column = columns[j];
                  if (typeof column.v !== 'undefined' && column.v !== null) {
                    rowData.push(column.v);
                  }
                }
              }
              rowData = this.removeColumns(rowData, itemsToRemove);
              if (chartNumber == 1) {
                outputData.chart1.data.push(rowData);
              } else if (chartNumber == 2) {
                outputData.chart2.data.push(rowData);
              } 
            }
          }
          if (typeof inputData.containerId !== 'undefined' && inputData.containerId !== null) {      
            if (chartNumber == 1) {
              outputData.chart1.containerId = inputData.containerId;
            } else if (chartNumber == 2) {
              outputData.chart2.containerId = inputData.containerId;
            }          
          }
        }
        return outputData;
    }
    removeColumns(rowData, itemsToRemove){
        // remove the last columns
        let removeColumnCount = 0;
        while (itemsToRemove.lastColumns > removeColumnCount) {
          let indexToRemove = rowData.length - 1;
          rowData.splice(indexToRemove, 1);
          removeColumnCount++;            
        }
        // remove the first columns
        let removeFirstColumnCount = 0;
        while (itemsToRemove.firstColumns > removeFirstColumnCount) {
          let indexToRemove = 0;
          rowData.splice(indexToRemove, 1);
          removeFirstColumnCount++;
        }
        if (typeof itemsToRemove.gapColumn != "undefined" && itemsToRemove.gapColumn != null){
          rowData.splice(itemsToRemove.gapColumn, 1);
        }
        // Remove the other columns last
        if (typeof itemsToRemove.otherColumns != "undefined" && itemsToRemove.otherColumns != null && itemsToRemove.otherColumns.length > 0){
          for (let i = 0; i < itemsToRemove.otherColumns.length; i++) {
            rowData.splice(itemsToRemove.otherColumns[i], 1);
          }
        }
        return rowData;
    }

    /***  Axis Label Functions*/  
    getDefaultAxisTextStyleForAnalyticChart() {
      let showAllLabel = {      
          showMinLabel: true,
          showMaxLabel:true,
      }
      let label = ScatterService.getDefaultAxisTextStyle();
      $.extend(true, label, showAllLabel);        
    }    
    getAxisLabelForMetric(formatter){
        return {
            color: "#339966",
            show: true,
            formatter: formatter,
        };
    }
    getAxisLabelForPercent(){
        return {
            color: "#339966",
            show: true,
            formatter: "{value}%",
        };
    }
    getToolboxForAnalyticsChart(){
      return {
        height: 'auto',
        left: 0,
        //top: 90,            
      }      
    }    
    getGridForSingleChart(includeBackgroud = true) {
      let grid1 = {
          top:80,
          width: 670,
          containLabel: true,
          bottom: "2.5%",
      };
      if (includeBackgroud == true){
          $.extend(true, grid1, ScatterService.getGridBackground());
      }
      let baseGrid = [];
      baseGrid.push(grid1);
      return baseGrid;
    }   
    /*** Header Functions */     
    getBaseNeedsHeader(breakPointList = [],numberOfNeeds = 14,displayType = 'need'){
      breakPointList = breakPointList.map(x => x + 1);
      let needsArray = [];
      for (let i = 0; i < numberOfNeeds; i++) {
        needsArray.push(i + 1);
      }
      let baseHeader = {
        gridIndex: 0,
        data: needsArray,
        position: "top",
        scale: 'value',
        axisTick: {
          show: true,
          length: 0,
          lineStyle: {
            color: '#000'
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
        },
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#C0C0C0"
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
        },            
      };
      if (displayType == "winner" || displayType == "match-winner" || displayType == "moat" || displayType == "match-moat" || displayType == "moat-ranks") {
        let splitLineForWinner = {        
            color: "#fff",
            width: 4,
        }
        baseHeader.splitLine.lineStyle = JSON.parse(JSON.stringify(splitLineForWinner));
      }
      return baseHeader;              
    }
    getMoatHeaderSeries(displayType, names = [], numberOfNeeds = 14, gridIndex = 0, xAxisIndex = 1, yAxisIndex = 0,displayPricingPower = true, headerGroups = []){
      let isEmotional = names.length == 5;
      let yellow = ['Design','Innovation','Routine','Curiosity'];
      let gold = ['Scale','Time','Skill','Strictness'];
      let lightBrown = ['Lock-In','Brand','Trust'];
      let brown = ['Network','Activity'];
      let darkBrown = ['Value','Lock-Out','Assurance'];

      let finalData = [];
      let base =  {           
        name: '',
        itemStyle: {  
          color: 'none',
        },
        label: {
          width: 35.5,
          overflow: 'overflow',
          ellipsis: '...',                    
          rich: {
            a : {
              align: 'center',
              fontSize: 8.5,
              fontFamily: "Franklin Gothic Medium Cond",                    
              lineHeight: 35,
              color: '#fff',
              verticalAlign: 'middle',
            }
          }
        }
      };
      let colorList = [];
      let backgroundColorList = [];
      let startList = [];
      let endList = [];
      for (let i = 0; i < names.length; i++) {
        if (isEmotional || displayType == "moat-ranks"){
          startList.push(i);
          endList.push(i);
        } else {
          startList.push(i+1);
          endList.push(i+1);
        }
        if (yellow.includes(names[i])) {
          backgroundColorList.push('#ffe48f')
          colorList.push('#000');
        } else if (gold.includes(names[i])) {
          backgroundColorList.push('#ffcd2f')
          colorList.push('#000');
        } else if (lightBrown.includes(names[i])) {
          backgroundColorList.push('#bf9000')
          colorList.push('#000');
        } else if (brown.includes(names[i])) {
          backgroundColorList.push('#826300');
          colorList.push('#fff');
        } else if (darkBrown.includes(names[i])) {
          backgroundColorList.push('#4c3a00')
          colorList.push('#fff');
        }
      }
      endList.push(String(numberOfNeeds));
      for (let i = 0; i < names.length; i++) {
        let newCoord = JSON.parse(JSON.stringify(base));
        let xCood = []
        // 1st Coord
        $.extend(true, newCoord, {name: names[i]});

        newCoord.label.offset = [0,0];

        if (displayType == "moat-ranks"){
          if (names[i] == 'Brand') {
            newCoord.label.width = 54;
            newCoord.label.offset = [0,0];
          } else if (names[i] == 'Value') {
            newCoord.label.width = 55;
            newCoord.label.offset = [13,0];
          } else if (names[i] == 'Design') {
            newCoord.label.offset = [-0.5,0];
            newCoord.label.width = 55;
          } else {
            newCoord.label.width = 54;
            newCoord.label.offset = [0,0];
          }
        } else if (displayType == "match-moat") {
          if (names[i] == 'Value') {
            newCoord.label.offset = [7,0];
            newCoord.label.width = 71.5;
          } else if (names[i] == 'Design') {
            newCoord.label.width = 36;
            newCoord.label.offset = [-2,0];
          }
        } else if (displayType == "moat") {
          if (names[i] == 'Value') {
            newCoord.label.width = 72;
            newCoord.label.offset = [7,0];
          } else if (names[i] == 'Design') {
            newCoord.label.width = 36;
            newCoord.label.offset = [-2,0];
          }
        } 
        if (isEmotional) {
          newCoord.label.width = 40;
        }
        if (displayPricingPower == false) {
          if (isEmotional){
            newCoord.label.width += 0;  
          }
          newCoord.label.width += 1;
        }
        $.extend(true, newCoord.label, {formatter: '{a|'+names[i]+'}'});
        $.extend(true, newCoord.label, {backgroundColor: backgroundColorList[i]});  
        $.extend(true, newCoord.label.rich.a, {color: colorList[i]});  
        xCood.push(newCoord);
        if (i > 0) {
          if (displayType == "moat-ranks" && names[i] == 'Brand') {
            newCoord.xAxis = 10;
          } else {
            newCoord.xAxis = startList[i]
          }
        } else if (i == 0) {
          if (isEmotional) {
            newCoord.label.offset = [9.0,0];
          } 
        }
        // 2nd Coord
        if (displayType == "moat-ranks" && names[i] == 'Brand') {
          xCood.push({xAxis: 10});
        } else {
          xCood.push({xAxis: endList[i]});
        }
        finalData.push(xCood);
      }
      // for new header groups - for moat & moat-ranks only
      if (displayType == "moat" || displayType == "moat-ranks" || displayType == "match-moat") {
        let baseHeader =  {           
          name: '',
          itemStyle: {  
            color: 'none',
          },
          label: {
            width: 111,
            overflow: 'overflow',
            ellipsis: '...',                    
            rich: {
              a : {
                align: 'center',
                fontSize: 16,
                fontFamily: "Franklin Gothic Medium Cond",                    
                lineHeight: 35,
                color: '#fff',
                verticalAlign: 'middle',
              }
            }
          }
        };
        
        backgroundColorList = [];
        let xCood= [];
        for (let i = 0; i < headerGroups.length; i++) {
          backgroundColorList.push('#4D8352');
          let newCoord = JSON.parse(JSON.stringify(baseHeader));
          xCood = [];
          // 1st Coord
          $.extend(true, newCoord, {name: headerGroups[i]});
          $.extend(true, newCoord.label, {formatter: '{a|'+headerGroups[i]+'}'});
          $.extend(true, newCoord.label, {backgroundColor: backgroundColorList[i]});  
          
          newCoord.label.offset = [45,-35];
          if(displayType == "moat-ranks") {
            newCoord.xAxis = 0;
            newCoord.label.offset = [28,-35];
            newCoord.label.width = 115;
          }
          if (headerGroups[i] == 'Early-Mover Moats') {
            if(displayType == "moat-ranks") {
              newCoord.xAxis = 4;
              newCoord.label.offset = [145,-35];
              newCoord.label.width = 345;
            } else {
              newCoord.xAxis = 4;
              newCoord.label.offset = [131,-35];
              newCoord.label.width = 224;
            }
          } else if (headerGroups[i] == 'Promise Moats') {
            if(displayType == "moat-ranks") {
              newCoord.xAxis = 17;
              newCoord.label.offset = [232,-35];
              newCoord.label.width = 170.5;
            } else {
              newCoord.xAxis = 17;
              newCoord.label.offset = [169,-35];
              newCoord.label.width = 110;
            }
          } else if (headerGroups[i] == 'Emotional Levels') {
            newCoord.xAxis = 0;
            newCoord.label.offset = [79.5,-35];
            if(displayType == "moat") {
              newCoord.label.width = 201;
            } else {
              newCoord.label.width = 202;
            }
          }
          xCood.push(newCoord);
          xCood.push({xAxis: 0});
          finalData.push(xCood);
        }
      }
      return {
        name: "Header",
        type: "scatter",
        label: {
          "show": false,
        },
        xAxisIndex: xAxisIndex,
        yAxisIndex: yAxisIndex,
        gridIndex: gridIndex,
        markArea: {
          label: {
            backgroundColor: '#2F5EB3',
            color: 'white',
            padding: [0,0,10,0],
            fontSize: 7,
            fontWeight: 'bold',
            height: 24,
            distance: 1,
          },
          data: finalData
        },
      };
    }    

    /*** Base Functions ***/
    getDefaultLineStyle(){
      return {
          lineStyle:{
              width: 5
          }
      };
    }
    getBaseConfig(symbolType = "none"){
        let symbol = {
            symbol: symbolType,
            zLevel: 10,
            z:10,
        };
        $.extend(true,symbol,this.getDefaultLineStyle());
        return symbol;
    }   
    getDefaultClientSymbolConfig(defaultSymbolType = 'circle'){
      if (defaultSymbolType == 'X'){
        //defaultSymbolType = 'path://M 10 10 L 90 10 L 90 90 L 10 90 Z'
        //defaultSymbolType = 'path://M261,18c6.93,5.94 13.86,11.88 21,18c0,9 0,9 -22.96875,32.84766c-9.74532,9.64089 -19.49064,19.28178 -29.53125,29.21484c-9.74532,9.66411 -19.49064,19.32819 -29.53125,29.28516c-7.57968,7.47528 -15.15939,14.95053 -22.96875,22.65234c20.29332,23.67555 42.02778,45.30972 64.125,67.3125c8.19069,8.16363 16.38141,16.32726 24.82032,24.73827c6.28803,6.25326 12.57609,12.50649 19.05468,18.94923c-5.94,6.93 -11.88,13.86 -18,21c-9,0 -9,0 -32.84766,-22.96875c-9.64089,-9.74532 -19.28178,-19.49064 -29.21484,-29.53125c-9.66411,-9.74532 -19.32819,-19.49064 -29.28516,-29.53125c-7.47528,-7.57968 -14.95053,-15.15939 -22.65234,-22.96875c-23.14362,19.83738 -44.3061,41.02302 -65.8125,62.625c-7.8852,7.91226 -15.7704,15.82452 -23.89452,23.97657c-6.03669,6.07149 -12.07338,12.14295 -18.29298,18.39843c-6.93,-5.94 -13.86,-11.88 -21,-18c0,-9 0,-9 22.3125,-32.19141c9.46689,-9.36246 18.93375,-18.72492 28.6875,-28.37109c14.20032,-14.07849 14.20032,-14.07849 28.6875,-28.44141c7.36311,-7.25871 14.72625,-14.51742 22.3125,-21.99609c-19.83738,-23.14362 -41.02302,-44.3061 -62.625,-65.8125c-7.91226,-7.8852 -15.82452,-15.7704 -23.97657,-23.89452c-9.10722,-9.05502 -9.10722,-9.05502 -18.39843,-18.29298c5.94,-6.93 11.88,-13.86 18,-21c9,0 9,0 32.19141,22.3125c9.36246,9.46689 18.72492,18.93375 28.37109,28.6875c14.07849,14.20032 14.07849,14.20032 28.44141,28.6875c7.25871,7.36311 14.51742,14.72625 21.99609,22.3125c23.67555,-20.29332 45.30972,-42.02778 67.3125,-64.125c8.16363,-8.19069 16.32726,-16.38141 24.73827,-24.82032c9.37986,-9.43206 9.37986,-9.43206 18.94923,-19.05468z'
        //defaultSymbolType = 'path://M797.32 985.882 344.772 1438.43l188.561 188.562 452.549-452.549 452.548 452.549 188.562-188.562-452.549-452.548 452.549-452.549-188.562-188.561L985.882 797.32 533.333 344.772 344.772 533.333z'
        defaultSymbolType = SymbolService.getXSymbol();
      }
      let symbolSize = 13;
      let symbol = {
          symbol: defaultSymbolType,
          symbolSize: symbolSize,
          zLevel: 12,
          z:12
      };
      $.extend(true,symbol,this.getDefaultLineStyle());
      return symbol;
    }       
    getCompanyConfig(symbolType = "none",symbolSize = 10){
        let symbol = {
            symbol: symbolType,
            symbolSize: symbolSize,
            zLevel: 12,
            z:12
        };
        $.extend(true,symbol,this.getDefaultLineStyle());
        return symbol;
    }       
    findMinMax(numbersArray,useMetricGrowth = false) {
      if (numbersArray.length === 0) return { min: null, max: null }; // Handle empty array
      let min = Math.min(...numbersArray);
      let max = Math.max(...numbersArray);

      if (useMetricGrowth == true){
        if (this.max < 100) {
          max = 100;
        } else {
          max = null;
        }
      } else {
        max = null;
      }
      if (min < 0) {
        min = null;
      } else {
        min = 0;
      }
    return { min: min, max: max };
  }    
}

