import { AwsRum } from 'aws-rum-web';

const rumEnabled = process.env.VUE_APP_RUM_ENABLED;
let isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
if (isInterviewApp == "false" || isInterviewApp == false) {
    if (rumEnabled == "true" || rumEnabled == true) {
    try {
        if (process.env.VUE_APP_RUM_ENVIRONMENT === "qa") {    
            const config = {
            sessionSampleRate: 1,
            guestRoleArn: "arn:aws:iam::805218563120:role/RUM-Monitor-us-east-1-805218563120-7790433166561-Unauth",
            identityPoolId: "us-east-1:8bd24803-068c-498e-8673-af89ca820d73",
            endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
            telemetries: ["performance","errors","http"],
            allowCookies: true,
            enableXRay: false
            };
            const APPLICATION_ID = '661443e7-0ea2-49c6-b84a-2e686a9f627c';
            const APPLICATION_VERSION = '1.0.0';
            const APPLICATION_REGION = 'us-east-1';

            const awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
            );
        } else if (process.env.VUE_APP_RUM_ENVIRONMENT === "production") {    
            const config = {
                sessionSampleRate: 1,
                guestRoleArn: "arn:aws:iam::805218563120:role/RUM-Monitor-us-east-1-805218563120-0045194456561-Unauth",
                identityPoolId: "us-east-1:14957562-1698-4a27-a9c4-f1fbef147c80",
                endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
                telemetries: ["performance","errors","http"],
                allowCookies: true,
                enableXRay: false
                };
                const APPLICATION_ID = 'ae081290-9c8e-4c79-a640-a82d2fbd3602';
                const APPLICATION_VERSION = '1.0.0';
                const APPLICATION_REGION = 'us-east-1';
        
                const awsRum = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                config
                );
        
        } else if (process.env.VUE_APP_RUM_ENVIRONMENT === "staging") {    
            const config = {
                sessionSampleRate: 1,
                guestRoleArn: "arn:aws:iam::805218563120:role/RUM-Monitor-us-east-1-805218563120-3210260166561-Unauth",
                identityPoolId: "us-east-1:9e561f25-07ce-40b4-877c-e632ecb0d78f",
                endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
                telemetries: ["performance","errors","http"],
                allowCookies: true,
                enableXRay: false
            };
            
            const APPLICATION_ID = 'ef1b88dc-b7f9-4971-b346-a9abba497947';
            const APPLICATION_VERSION = '1.0.0';
            const APPLICATION_REGION = 'us-east-1';
            
            const awsRum = new AwsRum(
                APPLICATION_ID,
                APPLICATION_VERSION,
                APPLICATION_REGION,
                config
            );            
        }
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
    }
}
